.main-terms{
    margin-bottom: 20px;
    .container-terms{
        display: flex;
        flex-direction: row;
        height: 550px;
        .terms-bar{
            width: 30%;
            border-radius: 5px;
            background-color: #d5ebe2;
            //border: 1px solid #007E46;
            color: black !important;
            box-shadow:0 0.7px 4px rgba(11,102,35,0.045),
                    0 1.9px 11.1px rgba(11,102,35,0.065),
                    0 4.5px 26.8px rgba(11,102,35,0.085),
                    0 15px 89px rgba(11,102,35,0.13);
            padding: 5px;
            overflow: auto;

            ul{
                text-align: right;
                padding: 10px 20px;
            }

            ul li{
                padding: 10px 0;
                position: relative;
                list-style-type: none;
                transition: all 0.5s ease;
                cursor: pointer;
                margin-bottom: 5px;

            }

            ul li:before {
                top: 0;
                content: "";
                position: absolute;
                right: -20px;
                width: 2px;
                height: 100%;
                opacity: 0;
                background:#007E46;
                transition: all 0.5s ease;
            }

            ul li.active, ul li:hover{
                color: #007E46;
                font-weight: bold;
            }

            ul li.active:before{
                opacity: 1;
                color: #007E46;
                font-weight: bold;
            }
        }

        .terms-details{
            // background-color: #d5ebe2;
            width: 70%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding: 5px 10px;

            .heading{
                text-align: center;
            }

            .details{
                margin-top: 10px;
                padding-left: 10px;
                height: calc(100% - 100px);
                overflow: auto;
            }
        }
    }

}