@import url('https://fonts.googleapis.com/css2?family=Bubblegum+Sans&display=swap');

main{
    margin-top: 150px;
    // height: 100vh;
    // background-image: url('../images/bg_web_poster.png');
    // background-position: center;
    // background-repeat: no-repeat;
    // background-size: cover;
    // object-fit: cover;

    .container{
        padding-top: 0 !important;
        .home-cover{
            display: flex;
            flex-direction: row;
            margin-top: 0 !important;
            height: 580px !important;
            .description{
                //background-color: cyan;
                width: 50%;
                display: flex;
                flex-direction: column;
                justify-content: center;
    
                .web-intro{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    text-align: center;
                    margin-right: 5px;
                    
                    .intro{
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        padding: 10px;
                        background-color: #ecf0ef;
                        color: #007E46;
                        text-align: center;
                        //border-radius: 10px;
                        //border: 1px solid #007E46;
    
                        .dot{
                            display: flex;
                                flex-direction: row;
                                justify-content: center;
                                padding: 5px;
                            span{
                                background-color: #007E46;
                                border-radius: 50%;
                                
                                height: 10px;
                                width:10px;
                            }
                            
                        }
    
                        .planas{
                            padding: 5px;
                            border-bottom: 1px solid #007E46;
                            h4{
                                margin-bottom: 0 !important;
                            }
                        }
    
                        .retail-shop{
                            padding: 5px;
                            h5{
                                margin-bottom: 0 !important;
                            }
                        }
    
                        .manufacturers{
                            padding: 5px;
                            h5{
                                margin-bottom: 0 !important;
                            }
                        }
    
                        .variety{
                            padding: 5px;
                            h5{
                                margin-bottom: 0 !important;
                            }
                        }
                    }
    
                    
    
                    .shop-now{
                        background-color: #007E46;
                        border: 1px solid transparent;
                        color: white;
                        width: 100%;
                        padding: 10px;
                        cursor: pointer;
    
                        h6{
                            margin-bottom: 0 !important;
                        }
                    }
    
                    .shop-now:hover{
                        background-color: white;
                        border: 1px solid #007E46;
                        color: #007E46;
                        transition: 1s;
                    }

                    .get-quote{
                        margin-top: 10px;
                        width: 100%;
                        padding: 10px;
                        cursor: pointer;
                        background-color: #fff;
                        border: 1px solid #C9A980;
                        color: #C9A980;

                        h6{
                            margin-bottom: 0 !important;
                        }
                    }

                    .get-quote:hover{
                        background-color: #C9A980;
                        border: 1px solid #C9A980;
                        color: #fff;
                        transition: 1s;
                    }

                    .gift-voucher{
                       margin-top: 10px;
                       display: flex;

                        input{
                            width: 100%;
                            text-align: center;
                            padding: 5px;
                            color: white;
                            border: 1px solid #EC6E25;
                            background-color: #C9A980;
                        }

                        .btn-redeem-coupon{
                            background-color: #C9A980;
                            color: white;
                            border: 1px solid #C9A980;
                            padding: 5px;
                            width: 30%;
                        }

                        .btn-redeem-coupon:hover{
                            background-color: white;
                            border: 1px solid #EC6E25;
                            color: #EC6E25;
                            transition: 1s;
                        }
                    }
                }
            }
        
            .image-video{
                width: 50%;
                height: 580px !important;
                background-color: rgba(63, 80, 68, 0.085);
                box-shadow:0 0.7px 4px rgba(11,102,35,0.045),
                    0 1.9px 11.1px rgba(11,102,35,0.065),
                    0 4.5px 26.8px rgba(11,102,35,0.085),
                    0 15px 89px rgba(11,102,35,0.13);
                position: relative;
                
            }
        }
    }

    .category-cards{
        margin-top: 20px;

        .adverts{
            height:100px;
            padding:5px;
            background-color:#ed6e24;
        }

        .first-category{
            margin-top:10px;
            height:450px;
            
            background-color:#007E46;

            .fi-heading{
                padding:5px;
                display: flex;
                justify-content: center;
                //background-color: black;
                color:white;
                padding: 10px;

                h4{
                    margin: 0 !important;
                    font-family: 'Bubblegum Sans', cursive;
                    font-size: 3rem;
                }
            }

            .fi-description{
                display: flex;
                justify-content: center;
                font-family: 'Bubblegum Sans', cursive;
                font-size: 4.5rem;
                font-weight: bold;
                color:#EC6E25;

                span{
                    background-color: white;
                    padding: 5px;
                }

                span:before{
                    content: '';
                    animation: first infinite 8s;
                }

                @keyframes first{
                    0%{
                        content: 'Business Cards';
                    }
                    30%{
                        content: 'Letterheads';
                    }
                    60%{
                        content: 'Invoices';
                    }
                    100%{
                        content: 'Receipts';
                    }
                }
            }

            .fi-cards{
                background-color: white;
                .cards{

                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    margin-top: 20px;

                    .first{
                        width: 180px;
                        height: 180px;
                        margin: 0 5px;
                        cursor: pointer;
                        box-shadow: 0 0 20px 2px rgba(0,0,0, .1);
                        transition: 1s;
                    }

                    .first:hover{
                        transform: scale(1.3);
                        z-index: 1;
                    }

                }
            }
        }

        .second-category{
            margin-top:10px;
            height:450px;
            background-color:#E5CBAE;

            .se-heading{
                display: flex;
                justify-content: center;
                //background-color: black;
                color:#007E46;
                padding: 10px;

                h4{
                    margin: 0 !important;
                    font-family: 'Bubblegum Sans', cursive;
                    font-size: 3rem;
                }
            }

            .se-description{
                display: flex;
                justify-content: center;
                font-family: 'Bubblegum Sans', cursive;
                font-size: 4.5rem;
                font-weight: bold;
                color:#fff;

                span{
                    background-color: #007E46;
                    padding: 5px;
                }

                span:before{
                    content: '';
                    animation: sec-animate infinite 8s;
                }

                @keyframes sec-animate{
                    0%{
                        content: 'Hole Punchers';
                    }
                    20%{
                        content: 'Staplers';
                    }
                    40%{
                        content: 'Staples';
                    }
                    60%{
                        content: 'Tapes';
                    }
                    80%{
                        content: 'Tape Dispensers';
                    }
                    100%{
                        content:'Envelopes';
                    }
                }
            }

            .se-cards{
                background-color: white;
                .cards{

                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    margin-top: 20px;

                    .first{
                        display: flex;
                        flex-wrap: wrap;
                        width: 180px;
                        height: 180px;
                        margin: 0 5px;
                        cursor: pointer;
                        box-shadow: 0 0 20px 2px rgba(0,0,0, .1);
                        transition: 1s;

                        img{
                            width: 100%;
                        }
                    }

                    .first:hover{
                        transform: scale(1.3);
                        z-index: 1;
                    }

                }
            }
        }

        .third-category{
            margin-top:10px;
            height:450px;
            padding:5px;
            background-color:#1fabdf;

            .th-heading{
                display: flex;
                justify-content: center;
                //background-color: black;
                color:#fff;
                padding: 10px;

                h4{
                    margin: 0 !important;
                    font-family: 'Bubblegum Sans', cursive;
                    font-size: 3rem;
                }
            }

            .th-description{
                display: flex;
                justify-content: center;
                font-family: 'Bubblegum Sans', cursive;
                font-size: 4.5rem;
                font-weight: bold;
                color:#1fabdf;

                span{
                    background-color: #fff;
                    padding: 5px;
                }

                span:before{
                    content: '';
                    animation: third infinite 8s;
                }

                @keyframes third{
                    0%{
                        content: 'Ballpoint Pens';
                    }
                    12%{
                        content: 'Fountain Pens';
                    }
                    20%{
                        content: 'Pencils';
                    }
                    28%{
                        content: 'Porous Point Pens';
                    }
                    36%{
                        content: 'Rollerball Pens';
                    }
                    44%{
                        content: 'Hightlighter Pens';
                    }
                    52%{
                        content: 'Brushes';
                    }
                    60%{
                        content: 'Water Colour';
                    }
                    68%{
                        content: 'Crayons';
                    }
                    76%{
                        content:'Rulers';
                    }
                    84%{
                        content:'Erasers';
                    }
                    92%{
                        content:'Sharpener';
                    }
                    100%{
                        content:'Board Compass';
                    }
                }
            }

            .th-cards{

            }
        }

        .fourth-category{
            margin-top:10px;
            height:450px;
            padding:5px;
            background-color:purple;

            .fo-heading{
                display: flex;
                justify-content: center;
                //background-color: black;
                color:#fff;
                padding: 10px;

                h4{
                    margin: 0 !important;
                    font-family: 'Bubblegum Sans', cursive;
                    font-size: 3rem;
                }
            }

            .fo-description{
                display: flex;
                justify-content: center;
                font-family: 'Bubblegum Sans', cursive;
                font-size: 4.5rem;
                font-weight: bold;
                color:purple;

                span{
                    background-color: #fff;
                    padding: 5px;
                }

                span:before{
                    content: '';
                    animation: fo-animate infinite 8s;
                }

                @keyframes fo-animate{
                    0%{
                        content: 'Ink Ribbons';
                    }
                    33%{
                        content: 'Inkjet Cartridges';
                    }
                    66%{
                        content: 'Laserjet Printer Toner';
                    }
                    100%{
                        content: 'Photocopier Toner';
                    }
                }
            }

            .fo-cards{

            }
        }

        .fifth-category{
            margin-top:10px;
            height:450px;
            padding:5px;
            background-color:#2614c5;
            color:white;

            .fif-heading{
                display: flex;
                justify-content: center;
                //background-color: black;
                color:#fff;
                padding: 10px;

                h4{
                    margin: 0 !important;
                    font-family: 'Bubblegum Sans', cursive;
                    font-size: 3rem;
                }
            }

            .fif-description{
                display: flex;
                justify-content: center;
                font-family: 'Bubblegum Sans', cursive;
                font-size: 4.5rem;
                font-weight: bold;
                color:#2614c5;

                span{
                    background-color: #fff;
                    padding: 5px;
                }

                span:before{
                    content: '';
                    animation: fif-animate infinite 8s;
                }

                @keyframes fif-animate{
                    0%{
                        content: 'Expandable Files';
                    }
                    10%{
                        content: 'File Folders';
                    }
                    20%{
                        content: 'File Folder FC';
                    }
                    30%{
                        content: 'Box File';
                    }
                    40%{
                        content: 'Arch Lever Files';
                    }
                    50%{
                        content: 'Suspension Files';
                    }
                    60%{
                        content: 'Flat Files';
                    }
                    70%{
                        content: 'Accessible Files';
                    }
                    80%{
                        content: 'Hanging File Folders';
                    }
                    90%{
                        content: 'Index Cards';
                    }
                    100%{
                        content: 'Two Pocket Portfolios';
                    }
                }
            }
            
            .fif-cards{

            }
        }

        .sixth-category{
            margin-top:10px;
            height:450px;
            padding:5px;
            background-color:orange;
            color:white;

            .si-heading{
                display: flex;
                justify-content: center;
                //background-color: black;
                color:#fff;
                padding: 10px;

                h4{
                    margin: 0 !important;
                    font-family: 'Bubblegum Sans', cursive;
                    font-size: 3rem;
                }
            }

            .si-description{
                display: flex;
                justify-content: center;
                font-family: 'Bubblegum Sans', cursive;
                font-size: 4.5rem;
                font-weight: bold;
                color:orange;

                span{
                    background-color: #fff;
                    padding: 5px;
                }

                span:before{
                    content: '';
                    animation: animate infinite 8s;
                }

                @keyframes animate{
                    0%{
                        content: 'Pencils';
                    }
                    14%{
                        content: 'Brushes';
                    }
                    28%{
                        content: 'Pens';
                    }
                    42%{
                        content: 'Water Colour';
                    }
                    56%{
                        content: 'Crayons';
                    }
                    70%{
                        content:'Rulers';
                    }
                    84%{
                        content:'Erasers';
                    }
                    100%{
                        content:'Sharpener';
                    }
                }
            }

            .si-cards{

            }
        }

        .seventh-category{
            margin-top:10px;
            height:450px;
            padding:5px;
            background-color:black;
            color:white;

            .sev-heading{
                display: flex;
                justify-content: center;
                //background-color: black;
                color:#fff;
                padding: 10px;

                h4{
                    margin: 0 !important;
                    font-family: 'Bubblegum Sans', cursive;
                    font-size: 3rem;
                }
            }

            .sev-description{
                display: flex;
                justify-content: center;
                font-family: 'Bubblegum Sans', cursive;
                font-size: 4.5rem;
                font-weight: bold;
                color:#000;

                span{
                    background-color: #fff;
                    padding: 5px;
                }

                span:before{
                    content: '';
                    animation: animate infinite 8s;
                }

                @keyframes animate{
                    0%{
                        content: 'White Boards';
                    }
                    25%{
                        content: 'White Board Duster';
                    }
                    50%{
                        content: 'Lasers';
                    }
                    75%{
                        content: 'Wall Stickers';
                    }
                    100%{
                        content: 'Manilla Sheets';
                    }
                }
            }

            .sev-cards{

            }
        }

        .eighth-category{
            margin-top:10px;
            height:450px;
            padding:5px;
            background-color:red;
            color:white;

            .ei-heading{
                display: flex;
                justify-content: center;
                //background-color: black;
                color:#fff;
                padding: 10px;

                h4{
                    margin: 0 !important;
                    font-family: 'Bubblegum Sans', cursive;
                    font-size: 3rem;
                }
            }

            .ei-description{
                display: flex;
                justify-content: center;
                font-family: 'Bubblegum Sans', cursive;
                font-size: 4.5rem;
                font-weight: bold;
                color:red;

                span{
                    background-color: #fff;
                    padding: 5px;
                }

                span:before{
                    content: '';
                    animation: ei-animate infinite 8s;
                }

                @keyframes ei-animate{
                    0%{
                        content: 'Flash Drives';
                    }
                    16%{
                        content: 'Building Blocks';
                    }
                    28%{
                        content: 'Dart Sport';
                    }
                    40%{
                        content: 'Tennis Balls';
                    }
                    52%{
                        content: 'Soccer Balls';
                    }
                    64%{
                        content: 'Netballs';
                    }
                    76%{
                        content:'Football Nets';
                    }
                    88%{
                        content:'Tennis Rackets';
                    }
                    100%{
                        content:'Volley Balls';
                    }
                }
            }

            .ei-cards{

            }
        }
    }
    
}

@media only screen and (max-width:540px){
    main{
    
        .container{
            padding-top: 0 !important;
            .home-cover{
                display: flex;
                flex-direction: column;
                margin-top: 0 !important;
                .description{
                    //background-color: cyan;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
        
                    .web-intro{
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        text-align: center;
                        margin: 10px;
                        
                        .intro{
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            padding: 10px;
                            background-color: #ecf0ef;
                            color: #007E46;
                            text-align: center;
                            //border-radius: 10px;
                            //border: 1px solid #007E46;
        
                            .dot{
                                display: flex;
                                    flex-direction: row;
                                    justify-content: center;
                                    padding: 5px;
                                span{
                                    background-color: #007E46;
                                    border-radius: 50%;
                                    
                                    height: 10px;
                                    width:10px;
                                }
                                
                            }
        
                            .planas{
                                padding: 5px;
                                border-bottom: 1px solid #007E46;
                                h4{
                                    margin-bottom: 0 !important;
                                }
                            }
        
                            .retail-shop{
                                padding: 5px;
                                h5{
                                    margin-bottom: 0 !important;
                                }
                            }
        
                            .manufacturers{
                                padding: 5px;
                                h5{
                                    margin-bottom: 0 !important;
                                }
                            }
        
                            .variety{
                                padding: 5px;
                                h5{
                                    margin-bottom: 0 !important;
                                }
                            }
                        }
        
                        
        
                        .shop-now{
                            background-color: #007E46;
                            border: 1px solid transparent;
                            color: white;
                            width: 100%;
                            padding: 10px;
                            cursor: pointer;
        
                            h6{
                                margin-bottom: 0 !important;
                            }
                        }
        
                        .shop-now:hover{
                            background-color: white;
                            border: 1px solid #007E46;
                            color: #007E46;
                            transition: 1s;
                        }
    
                        .get-quote{
                            margin-top: 10px;
                            width: 100%;
                            padding: 10px;
                            cursor: pointer;
                            background-color: #fff;
                            border: 1px solid #C9A980;
                            color: #C9A980;
    
                            h6{
                                margin-bottom: 0 !important;
                            }
                        }
    
                        .get-quote:hover{
                            background-color: #C9A980;
                            border: 1px solid #C9A980;
                            color: #fff;
                            transition: 1s;
                        }
    
                        .gift-voucher{
                           margin-top: 10px;
                           display: flex;
    
                            input{
                                width: 100%;
                                text-align: center;
                                padding: 5px;
                                color: white;
                                border: 1px solid #EC6E25;
                                background-color: #C9A980;
                            }
    
                            .btn-redeem-coupon{
                                background-color: #C9A980;
                                color: white;
                                border: 1px solid #C9A980;
                                padding: 5px;
                                width: 30%;
                            }
    
                            .btn-redeem-coupon:hover{
                                background-color: white;
                                border: 1px solid #EC6E25;
                                color: #EC6E25;
                                transition: 1s;
                            }
                        }
                    }
                }
            
                .image-video{
                    width: 100%;
                    background-color: rgba(63, 80, 68, 0.085);
                    box-shadow:0 0.7px 4px rgba(11,102,35,0.045),
                        0 1.9px 11.1px rgba(11,102,35,0.065),
                        0 4.5px 26.8px rgba(11,102,35,0.085),
                        0 15px 89px rgba(11,102,35,0.13);
            
                    .image-cover{
                        border: 1px solid white;
                        border-radius: 10px;
                        padding: 0 !important;
                        text-align: center;
            
                        .image{
                            height: 100%;
                            width: 100%;
                        }
                    }
                    
                }
            }
        }
        
    }
}

@media only screen and (max-width:390px){
    main{
    
        .container{
            padding-top: 0 !important;
            .home-cover{
                display: flex;
                flex-direction: column;
                margin-top: 0 !important;
                .description{
                    //background-color: cyan;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
        
                    .web-intro{
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        text-align: center;
                        margin: 10px;
                        
                        .intro{
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            padding: 10px;
                            background-color: #ecf0ef;
                            color: #007E46;
                            text-align: center;
                            //border-radius: 10px;
                            //border: 1px solid #007E46;
        
                            .dot{
                                display: flex;
                                    flex-direction: row;
                                    justify-content: center;
                                    padding: 5px;
                                span{
                                    background-color: #007E46;
                                    border-radius: 50%;
                                    
                                    height: 10px;
                                    width:10px;
                                }
                                
                            }
        
                            .planas{
                                padding: 5px;
                                border-bottom: 1px solid #007E46;
                                h4{
                                    margin-bottom: 0 !important;
                                }
                            }
        
                            .retail-shop{
                                padding: 5px;
                                h5{
                                    margin-bottom: 0 !important;
                                }
                            }
        
                            .manufacturers{
                                padding: 5px;
                                h5{
                                    margin-bottom: 0 !important;
                                }
                            }
        
                            .variety{
                                padding: 5px;
                                h5{
                                    margin-bottom: 0 !important;
                                }
                            }
                        }
        
                        
        
                        .shop-now{
                            background-color: #007E46;
                            border: 1px solid transparent;
                            color: white;
                            width: 100%;
                            padding: 10px;
                            cursor: pointer;
        
                            h6{
                                margin-bottom: 0 !important;
                            }
                        }
        
                        .shop-now:hover{
                            background-color: white;
                            border: 1px solid #007E46;
                            color: #007E46;
                            transition: 1s;
                        }
    
                        .get-quote{
                            margin-top: 10px;
                            width: 100%;
                            padding: 10px;
                            cursor: pointer;
                            background-color: #fff;
                            border: 1px solid #C9A980;
                            color: #C9A980;
    
                            h6{
                                margin-bottom: 0 !important;
                            }
                        }
    
                        .get-quote:hover{
                            background-color: #C9A980;
                            border: 1px solid #C9A980;
                            color: #fff;
                            transition: 1s;
                        }
    
                        .gift-voucher{
                           margin-top: 10px;
                           display: flex;
    
                            input{
                                width: 100%;
                                text-align: center;
                                padding: 5px;
                                color: white;
                                border: 1px solid #EC6E25;
                                background-color: #C9A980;
                            }
    
                            .btn-redeem-coupon{
                                background-color: #C9A980;
                                color: white;
                                border: 1px solid #C9A980;
                                padding: 5px;
                                width: 30%;
                            }
    
                            .btn-redeem-coupon:hover{
                                background-color: white;
                                border: 1px solid #EC6E25;
                                color: #EC6E25;
                                transition: 1s;
                            }
                        }
                    }
                }
            
                .image-video{
                    width: 100%;
                    background-color: rgba(63, 80, 68, 0.085);
                    box-shadow:0 0.7px 4px rgba(11,102,35,0.045),
                        0 1.9px 11.1px rgba(11,102,35,0.065),
                        0 4.5px 26.8px rgba(11,102,35,0.085),
                        0 15px 89px rgba(11,102,35,0.13);
            
                    .image-cover{
                        border: 1px solid white;
                        border-radius: 10px;
                        padding: 0 !important;
                        text-align: center;
            
                        .image{
                            height: 100%;
                            width: 100%;
                        }
                    }
                    
                }
            }
        }
        
    }
}

@media only screen and (max-width:250px){
    main{
    
        .container{
            padding-top: 0 !important;
            .home-cover{
                display: flex;
                flex-direction: column;
                margin-top: 0 !important;
                .description{
                    //background-color: cyan;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
        
                    .web-intro{
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        text-align: center;
                        margin: 10px;
                        
                        .intro{
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            padding: 10px;
                            background-color: #ecf0ef;
                            color: #007E46;
                            text-align: center;
                            //border-radius: 10px;
                            //border: 1px solid #007E46;
        
                            .dot{
                                display: flex;
                                    flex-direction: row;
                                    justify-content: center;
                                    padding: 5px;
                                span{
                                    background-color: #007E46;
                                    border-radius: 50%;
                                    
                                    height: 10px;
                                    width:10px;
                                }
                                
                            }
        
                            .planas{
                                padding: 5px;
                                border-bottom: 1px solid #007E46;
                                h4{
                                    margin-bottom: 0 !important;
                                }
                            }
        
                            .retail-shop{
                                padding: 5px;
                                h5{
                                    margin-bottom: 0 !important;
                                }
                            }
        
                            .manufacturers{
                                padding: 5px;
                                h5{
                                    margin-bottom: 0 !important;
                                }
                            }
        
                            .variety{
                                padding: 5px;
                                h5{
                                    margin-bottom: 0 !important;
                                }
                            }
                        }
        
                        
        
                        .shop-now{
                            background-color: #007E46;
                            border: 1px solid transparent;
                            color: white;
                            width: 100%;
                            padding: 10px;
                            cursor: pointer;
        
                            h6{
                                margin-bottom: 0 !important;
                            }
                        }
        
                        .shop-now:hover{
                            background-color: white;
                            border: 1px solid #007E46;
                            color: #007E46;
                            transition: 1s;
                        }
    
                        .get-quote{
                            margin-top: 10px;
                            width: 100%;
                            padding: 10px;
                            cursor: pointer;
                            background-color: #fff;
                            border: 1px solid #C9A980;
                            color: #C9A980;
    
                            h6{
                                margin-bottom: 0 !important;
                            }
                        }
    
                        .get-quote:hover{
                            background-color: #C9A980;
                            border: 1px solid #C9A980;
                            color: #fff;
                            transition: 1s;
                        }
    
                        .gift-voucher{
                           margin-top: 10px;
                           display: flex;
    
                            input{
                                width: 100%;
                                text-align: center;
                                padding: 5px;
                                color: white;
                                border: 1px solid #EC6E25;
                                background-color: #C9A980;
                            }
    
                            .btn-redeem-coupon{
                                background-color: #C9A980;
                                color: white;
                                border: 1px solid #C9A980;
                                padding: 5px;
                                width: 30%;
                            }
    
                            .btn-redeem-coupon:hover{
                                background-color: white;
                                border: 1px solid #EC6E25;
                                color: #EC6E25;
                                transition: 1s;
                            }
                        }
                    }
                }
            
                .image-video{
                    width: 100%;
                    background-color: rgba(63, 80, 68, 0.085);
                    box-shadow:0 0.7px 4px rgba(11,102,35,0.045),
                        0 1.9px 11.1px rgba(11,102,35,0.065),
                        0 4.5px 26.8px rgba(11,102,35,0.085),
                        0 15px 89px rgba(11,102,35,0.13);
            
                    .image-cover{
                        border: 1px solid white;
                        border-radius: 10px;
                        padding: 0 !important;
                        text-align: center;
            
                        .image{
                            height: 100%;
                            width: 100%;
                        }
                    }
                    
                }
            }
        }
        
    }
}