.notFound{
    margin-top: 70px;
    display: flex;
    flex-direction: row;
    justify-content: center;

    .text{
        display: flex;
        flex-direction: column;
        justify-content: center;

        a{
            color: #5039fe !important;
            border: 1px solid transparent;
            width: 200px;
            border-radius: 5px;
            padding: 5px;
        }

        a:hover{
            color: #fff !important;
            background-color: #007E46;
            text-decoration: none;
            width: 200px;
            border-radius: 5px;
            padding: 5px;
            transition: 1ms;
        }
    }
}