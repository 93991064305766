.container{
    .about{
        margin-bottom: 10px;
    }
    .about, .services{
        padding: 10px;
        box-shadow:0 0.7px 4px rgba(11,102,35,0.045),
                    0 1.9px 11.1px rgba(11,102,35,0.065),
                    0 4.5px 26.8px rgba(11,102,35,0.085),
                    0 15px 89px rgba(11,102,35,0.13);
        border-radius: 10px;
    }

    .services{
        margin-bottom: 10px;
    }
}