.slider{
    height: 100%;
    margin:0px auto 0 auto;
    position: relative;
    overflow: hidden;
}

.slide{
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    opacity:0;
    transform: translateX(-50%);
    transition: all .5s ease;
}

.slide img{
    width: 100%;
    height: 100%; 
}

@media screen and (max-width:600px){
    .slide img{
        width: 100%;
        height: 100%;
    }
}

.slide img{
    height: 100%;
    width: 100%;
}

.content{
    position: absolute;
    top:5rem;
    left:5rem;
    opacity:0;
    width:50%;
    padding:1rem;
    background:rgba(0,0,0,.3);
    animation:slide-up 1s ease .5s;
    animation-fill-mode:forwards;
}

.content .btn:hover{
    color: #fff;
    background: #007E46;
    cursor: pointer;
}

@keyframes slide-up{
    0%{
        visibility:visible;
        top:16rem;
    }
    100%{
        visibility:visible;
        top:10rem;
    }
}

@media only screen and (max-width:600px){
    .slider{
        margin:70px auto 10px auto;
        width:100%;
        height: 200px;
    }
    
    .content{
        width:65%;
    }

    .content h2{
        font-size: 1rem;
    }

    .content p{
        font-size: .7rem;
    }

    @keyframes slide-up{
        0%{
            visibility:visible;
            top:12.5rem;
        }
        100%{
            visibility:visible;
            top:6.5rem;
        }
    }
}

@media only screen and (max-width:390px){
    .slider{
        width:100%;
        height: 150px;
    }

    .slide img{
        width: 100%;
        height: 100%;
    }
    
    .content{
        width:65%;
    }

    .content h2{
        font-size: 0.7rem;
    }

    .content p{
        font-size: .5rem;
    }

    @keyframes slide-up{
        0%{
            visibility:visible;
            top:12.5rem;
        }
        100%{
            visibility:visible;
            top:6.5rem;
        }
    }
}

.content > * {

    color:#fff;
    margin-bottom:1rem;
}

.current {
    opacity:1;
    transform: translateX(0);
}

.current .content{
    opacity:1;
}

.arrow{
    border:2px solid #fff;
    border-radius: 50%;
    background:transparent;
    color:#fff;
    width: 2rem;
    height:2rem;
    cursor: pointer;
    position: absolute;
    z-index:500;
}

.arrow:hover{
    background: #fff;
    color:#777;
}

.next{
    top:35%;
    right:1.5rem;
}

.prev{
    top:35%;
    left:1.5rem;
}

hr{
    height:2px;
    background: #fff;
    width: 50%;
}