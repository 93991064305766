.header{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    z-index: 10;
    background-color: #007E46;
    width: 100%;

    .contacts-header{
        color: white;
        display: flex;
        flex-direction: row;
        padding: 2px;
        justify-content: space-between;

        .social{
            display: flex;
            flex-direction: row;
            width: 10%;

        }

        .contact-us{
            width: 90%;
            display: flex;
            flex-wrap: wrap;


            .contact-card{
                cursor: pointer;
                background-color: #fff;
                color: #007E46;
                margin: 2px;
                font-size: .8rem;
                padding: 2px 8px;
                border-radius: 20px;
                border: 1px solid transparent;
            }

            .contact-card:hover{
                cursor: pointer;
                background-color: #ed6e24;
                color: #fff;
                transition: 1s;
            }
        }
    }

    .header-main{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        box-shadow:0 0.7px 4px rgba(11,102,35,0.045),
                    0 1.9px 11.1px rgba(11,102,35,0.065),
                    0 4.5px 26.8px rgba(11,102,35,0.085),
                    0 15px 89px rgba(11,102,35,0.13);
    
        .logoLink{
            position: relative;
            img{
                height:35px;
                width:40px;
            }
        }
    
        .active{
            color:#fff !important;}
    
        .nav-menu {
            display: flex;
            align-items: center;
    
            ul{
                display: flex;
                justify-content: space-between;
                list-style: none;
                padding: 0 0 0 10px;
                margin-bottom: 0 !important;
    
                li{
                    margin: 5px 5px 0 5px;
                    padding: 5px;
    
                    a{
                        color: #fff;
                        text-decoration: none;
                    }
                }
    
            
            }
    
            ul li:hover{
                    padding: 5px;
                    background-color: #ed6e24;
                    box-shadow: 0 0.7px 4px rgba(238, 113, 41);
    
                    a{
                        color: #fff;
                    }
            }
        }
    }
}