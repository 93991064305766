.footer{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    background-color: white;
    padding: 20px;
    max-width:100%;
    margin:auto auto 1rem auto;
    color:#007E46;

    .logo{
        text-align: center;
        img{
            height: 130px;
            width: 280px;
        }
    }
    .company{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 12px;
        cursor: pointer;

        a{
            text-decoration: none;
            color: #007E46;

        }

        p{
            background-color: #ed6e24 !important;
            text-decoration: none;
            border-radius: 5px;
            color: #fff;
            padding:5px;
        }

        a p:hover{
            background-color: #007E46;
            color: #fff;
            padding:5px;
        }

        a:hover{
            color: #007E46;
        }
    }

    .social-media{
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 10px;
        font-size: 12px;
        cursor: pointer;

        .twitter{
            padding: 10px;
        }

        .twitter:hover{
            svg{
                color: rgb(31, 170, 235) !important; 
            }
            
        }

        .facebook{
            padding: 10px;
        }

        .facebook:hover{
            svg{
                color: blue !important; 
            }
            
        }

        .instagram{
            padding: 10px;
        }

        .instagram:hover{
            svg{
                color: rgb(255, 143, 15) !important; 
            }
            
        }
    }

    .retail-shops{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 12px;
        cursor: pointer;

        a{
            text-decoration: none;
            color: #fff;
        }

        p{
            text-decoration: none;
            color: #fff;
            padding:5px;
        }

        a p:hover{
            background-color: white !important;
            border-radius: 5px;
            color: #007E46;
            padding:5px;
        }
    }

    .all-rights{
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        cursor: pointer;
    }

    .developer{
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        font-size: 12px;
        cursor: pointer;

        a{
            text-decoration: none;
            color: #fff;
        }

        a:hover{
            color: orange;
        }
    }
}

@media only screen and (max-width:540px){
    .footer{
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        background-color: #E5CBAE;
        padding: 20px;
        max-width:100%;
        margin:auto auto 1rem auto;
    
        .logo{
            text-align: center;
            img{
                height: 85px !important;
                width: 350px;
            }
        }
        .company{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
            font-size: 12px;
            cursor: pointer;
    
            a{
                text-decoration: none;
                color: #000;
    
            }
    
            a:hover{
                color: #007E46;
            }
        }
    
        .social-media{
            display: flex;
            flex-direction: row;
            justify-content: center;
            padding: 10px;
            font-size: 12px;
            cursor: pointer;
    
            .twitter{
                padding: 10px;
            }
    
            .twitter:hover{
                svg{
                    color: rgb(31, 170, 235) !important; 
                }
                
            }
    
            .facebook{
                padding: 10px;
            }
    
            .facebook:hover{
                svg{
                    color: blue !important; 
                }
                
            }
    
            .instagram{
                padding: 10px;
            }
    
            .instagram:hover{
                svg{
                    color: white !important; 
                }
                
            }
        }
    
        .retail-shops{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
            font-size: 12px;
            cursor: pointer;
    
            a{
                text-decoration: none;
                color: #000;
    
            }
    
            a:hover{
                color: #007E46;
            }
        }
    
        .all-rights{
            margin-top: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            font-size: 12px;
            cursor: pointer;
        }
    
        .developer{
            margin-top: 10px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            font-size: 12px;
            cursor: pointer;
    
            a{
                text-decoration: none;
                color: #007E46;
            }
    
            a:hover{
                color: blue;
            }
        }
    }
}

@media only screen and (max-width:390px){
    .footer{
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        background-color: #E5CBAE;
        padding: 20px;
        max-width:100%;
        margin:auto auto 1rem auto;
    
        .logo{
            text-align: center;
            img{
                height: 65px !important;
                width: 280px;
            }
        }
        .company{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
            font-size: 12px;
            cursor: pointer;
    
            a{
                text-decoration: none;
                color: #000;
    
            }
    
            a:hover{
                color: #007E46;
            }
        }
    
        .social-media{
            display: flex;
            flex-direction: row;
            justify-content: center;
            padding: 10px;
            font-size: 12px;
            cursor: pointer;
    
            .twitter{
                padding: 10px;
            }
    
            .twitter:hover{
                svg{
                    color: rgb(31, 170, 235) !important; 
                }
                
            }
    
            .facebook{
                padding: 10px;
            }
    
            .facebook:hover{
                svg{
                    color: blue !important; 
                }
                
            }
    
            .instagram{
                padding: 10px;
            }
    
            .instagram:hover{
                svg{
                    color: white !important; 
                }
                
            }
        }
    
        .retail-shops{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
            font-size: 12px;
            cursor: pointer;
    
            a{
                text-decoration: none;
                color: #000;
    
            }
    
            a:hover{
                color: #007E46;
            }
        }
    
        .all-rights{
            margin-top: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            font-size: 12px;
            cursor: pointer;
        }
    
        .developer{
            margin-top: 10px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            font-size: 12px;
            cursor: pointer;
    
            a{
                text-decoration: none;
                color: #007E46;
            }
    
            a:hover{
                color: blue;
            }
        }
    }
}